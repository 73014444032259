/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  min-height: 100%;
  position: relative; }

div.container div.container {
  padding-bottom: 55px; }

a {
  color: #1a0dab;
  text-decoration: none; }
  a svg.icon-svg {
    vertical-align: text-bottom;
    width: auto;
    height: 14px;
    fill: currentcolor; }

a:hover {
  text-decoration: underline; }

div.apc {
  margin-top: 40px; }
  div.apc div.src {
    float: left;
    width: 0; }
    @media only screen and (max-width: 1024px) {
      div.apc div.src {
        width: 100%;
        float: none; } }
  div.apc div.facts p.source {
    color: #006621; }
  div.apc svg.icon-external {
    margin-left: 4px; }
  div.apc div.abstract {
    padding-bottom: 55px;
    font-size: 14px;
    margin-left: 150px;
    width: 632px; }
    @media only screen and (max-width: 1024px) {
      div.apc div.abstract {
        width: auto;
        margin: 0 10px; } }
    div.apc div.abstract div.result p {
      line-height: 1.7;
      font-size: 14px; }
    div.apc div.abstract div.authors {
      color: #666;
      padding-bottom: 10px; }
    div.apc div.abstract div.source {
      padding-top: 5px;
      color: #006621; }
    div.apc div.abstract h1, div.apc div.abstract h2 {
      font-size: 16px; }
    div.apc div.abstract h2 {
      color: #660055; }

div.start {
  max-width: 584px;
  margin: auto;
  padding-bottom: 55px; }

div.logo {
  padding: 200px 0 20px 0;
  font-size: 88px;
  text-align: center;
  color: #3876a5;
  cursor: default; }
  @media only screen and (max-width: 1024px) {
    div.logo {
      padding-top: 150px; } }
  div.logo svg {
    width: 366px;
    height: auto; }
    @media only screen and (max-width: 1024px) {
      div.logo svg {
        width: 300px; } }

div.buttons {
  padding-top: 10px;
  text-align: center; }
  div.buttons button, div.buttons a.button {
    margin: 11px 4px; }

div.srpc {
  min-width: 1100px; }
  @media only screen and (max-width: 1024px) {
    div.srpc {
      min-width: auto; }
      div.srpc div.logo {
        padding: 0;
        font-size: 32px; }
      div.srpc div.query {
        width: auto;
        margin: 0 10px;
        padding: 0;
        font-size: 32px;
        position: relative;
        left: auto;
        top: auto; } }
  div.srpc div.src {
    float: left;
    width: 0; }
  div.srpc.loading.container {
    opacity: 0.2; }
  div.srpc div.results {
    margin-left: 150px;
    width: 632px; }
    div.srpc div.results div.suggest {
      font-size: 18px;
      padding-bottom: 26px; }
  div.srpc div.filters {
    margin-left: 150px;
    padding-bottom: 12px; }
    div.srpc div.filters div.filter {
      display: inline-block; }
      div.srpc div.filters div.filter > * {
        display: inline-block; }
      div.srpc div.filters div.filter ul {
        margin: 0;
        padding: 0px;
        list-style-type: none; }
  div.srpc div.stats {
    margin-left: 150px;
    color: #808080;
    font-size: 13px;
    padding-top: 12px; }
  div.srpc div.result {
    margin-bottom: 26px;
    line-height: 1.3;
    font-size: 14px; }
    div.srpc div.result span.authors {
      color: #666;
      display: inline; }
    div.srpc div.result span.source {
      color: #006621;
      display: inline; }
    div.srpc div.result a.title {
      font-size: 18px; }
    div.srpc div.result a:hover {
      text-decoration: underline; }
    div.srpc div.result a:visited {
      color: #609; }
    div.srpc div.result div.snippet {
      line-height: 1.4; }
    div.srpc div.result span.date {
      color: #808080; }
    div.srpc div.result div.actions a, div.srpc div.result div.actions span {
      margin-right: 8px; }
    div.srpc div.result div.actions span.score {
      color: #999; }

div.srpc.start {
  background-color: #fafafa;
  max-width: none;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0 0 0;
  position: relative;
  height: 85px; }
  div.srpc.start div.logo {
    padding: 0;
    font-size: 32px;
    position: absolute;
    left: 12px;
    top: 16px;
    display: inline-block; }
    div.srpc.start div.logo svg {
      width: 124px;
      height: 23px; }
  div.srpc.start div.buttons {
    display: none; }

@media only screen and (max-width: 1024px) {
  div.srpc.start {
    height: 105px; }
    div.srpc.start div.logo {
      left: 12px;
      top: -24px;
      position: relative;
      display: inline-block; }
  div.srpc div.src {
    width: 100%;
    float: none; }
  div.srpc div.results, div.srpc div.stats, div.srpc div.filters {
    padding-left: 0px;
    margin: 0 10px;
    width: auto; } }

div.pager {
  text-align: center; }
  div.pager a {
    padding: 0 5px;
    font-size: 14px; }
    div.pager a.previous {
      padding-right: 30px; }
    div.pager a.next {
      padding-left: 30px; }
    div.pager a.active {
      color: #000;
      cursor: default;
      font-weight: bold; }
      div.pager a.active:hover {
        text-decoration: none; }

.label-default {
  border-color: #777;
  color: #777; }

.label-success {
  border-color: #5cb85c;
  color: #5cb85c; }

.label-info {
  border-color: #5bc0de;
  color: #5bc0de; }

.label-warning {
  border-color: #f0ad4e;
  color: #f0ad4e; }

.label-danger {
  border-color: #d9534f;
  color: #d9534f; }

.label-primary, .label-primary:visited {
  border-color: #1a0dab;
  color: #1a0dab !important; }

span.label, a.label, a.label:hover {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  text-decoration: none !important;
  border-width: 1px;
  border-style: solid; }

footer {
  display: block;
  height: 35px;
  background-color: #f2f2f2;
  border-top: 1px solid #e4e4e4;
  font-size: 12px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0; }
  footer ul {
    margin: 0;
    padding: 0;
    line-height: 35px;
    text-align: center; }
    footer ul li {
      display: inline-block;
      padding: 0 15px; }
      footer ul li a {
        text-decoration: none;
        color: #777; }

.footer-container.show-privacy footer {
  height: 135px; }

.footer-container.show-privacy div.footer-privacy {
  display: block; }

.footer-container.show-privacy > div.container div.container {
  padding-bottom: 130px; }

@media only screen and (max-width: 1024px) {
  .footer-container.show-privacy footer {
    height: 175px; }
  .footer-container.show-privacy div.footer-privacy {
    display: block; }
  .footer-container.show-privacy > div.container div.container {
    padding-bottom: 190px; } }

div.footer-privacy {
  display: none;
  position: fixed;
  bottom: 0;
  height: 100px;
  background-color: #666;
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
  padding-right: 185px;
  line-height: 1.7; }
  div.footer-privacy > * {
    display: inline; }
  div.footer-privacy span {
    font-weight: bold; }
  div.footer-privacy p a {
    color: #ddd;
    text-decoration: underline; }
  div.footer-privacy div.buttons {
    padding-top: 0px;
    float: right;
    width: 160px;
    margin-right: -160px; }
  @media only screen and (max-width: 1024px) {
    div.footer-privacy {
      height: 120px;
      font-size: 12px;
      padding: 10px 15px; }
      div.footer-privacy div.buttons {
        position: absolute;
        bottom: 0;
        right: 15px;
        margin-right: 0px; }
        div.footer-privacy div.buttons button, div.footer-privacy div.buttons a.button {
          height: 28px; } }

div.facts {
  height: 0;
  padding: 0;
  width: 428px;
  margin-left: 820px; }
  @media only screen and (max-width: 1024px) {
    div.facts {
      display: none; } }
  @media only screen and (max-width: 1024px) {
    div.facts div.srpc > div.facts {
      width: 358px; } }
  @media only screen and (max-width: 1024px) {
    div.facts div.srpc > div.facts {
      width: 258px; } }
  div.facts.facts-mobile {
    margin-left: 150px;
    float: none;
    width: 632px;
    display: none; }
    @media only screen and (max-width: 1024px) {
      div.facts.facts-mobile {
        margin: 0 10px;
        width: auto;
        display: block; }
        div.facts.facts-mobile .fact > .header, div.facts.facts-mobile .fact > .body {
          padding: 10px 10px; }
        div.facts.facts-mobile .header h1, div.facts.facts-mobile .header span.category {
          font-weight: normal;
          display: inline;
          font-size: 16px; }
        div.facts.facts-mobile .body {
          display: none; } }
  div.facts div.fact {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    margin-bottom: 24px;
    font-size: 14px;
    border-top: 5px rgba(0, 0, 0, 0.16) solid; }
    div.facts div.fact > div {
      margin: 0;
      padding: 15px 20px; }
    div.facts div.fact h1 {
      font-size: 18px; }
    div.facts div.fact h1, div.facts div.fact .body ul {
      margin: 0;
      padding: 0px;
      list-style-type: none; }
      div.facts div.fact h1 li, div.facts div.fact .body ul li {
        padding: 5px 0px; }
    div.facts div.fact span.category, div.facts div.fact div.header div.locale a.locale-header {
      color: #aaa;
      font-weight: normal;
      display: block;
      font-size: 16px; }
    div.facts div.fact div.header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
      position: relative; }
      div.facts div.fact div.header div.locale {
        position: absolute;
        bottom: 15px;
        right: 20px; }
        div.facts div.fact div.header div.locale a.locale-header {
          text-decoration: none;
          cursor: pointer; }
          div.facts div.fact div.header div.locale a.locale-header:hover {
            color: black; }
            div.facts div.fact div.header div.locale a.locale-header:hover i.down {
              border-color: black; }
        div.facts div.fact div.header div.locale ul {
          text-align: right; }
    div.facts div.fact li > span {
      font-weight: bold; }
  @media only screen and (max-device-width: 1024px) {
    div.facts {
      margin: 0 10px;
      height: auto;
      display: block;
      width: auto; }
      div.facts .fact > .header, div.facts .fact > .body {
        padding: 10px 10px; }
      div.facts div.fact div.header div.locale {
        display: none; }
      div.facts div.fact div.header h1, div.facts div.fact div.header span.category {
        font-weight: normal;
        display: inline;
        font-size: 16px; }
      div.facts div.fact div.header span.category {
        float: right; }
      div.facts .body {
        display: none; }
      div.facts .body.active {
        display: block; } }

div.container.dropdown-active div.dropdown {
  display: block;
  margin-left: 150px; }

div.query-container {
  position: relative;
  padding: 10px;
  text-align: left; }
  div.query-container div.query, div.query-container div.dropdown {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  div.query-container div.dropdown-container {
    width: 100%;
    padding-right: 10px;
    max-width: 564px;
    position: relative;
    z-index: 1; }
  div.query-container div.dropdown {
    position: absolute;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    width: 100%; }
    div.query-container div.dropdown ul {
      padding: 0;
      margin: 0;
      display: block;
      list-style-type: none; }
      div.query-container div.dropdown ul li {
        padding: 5px 9px;
        display: block; }
        div.query-container div.dropdown ul li:hover, div.query-container div.dropdown ul li.active {
          background-color: #eee; }

div.query {
  height: 44px;
  vertical-align: top; }
  div.query div.query-shell {
    padding-left: 16px;
    padding: 6px 9px 0;
    white-space: nowrap;
    line-height: 0;
    overflow: hidden;
    height: 100%;
    position: relative; }
  div.query input, div.query span.completion {
    z-index: 15;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    border: none;
    padding: 0px;
    margin: 0px;
    line-height: 34px;
    height: 34px;
    border: none;
    outline: none;
    position: absolute;
    left: 9px;
    top: 6px; }
  div.query input.completion {
    z-index: 10;
    color: #ccc;
    -webkit-text-fill-color: #ccc;
    opacity: 1; }

div.query.active {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); }

div.srpc div.query, div.srpc div.dropdown {
  width: 632px; }

div.srpc div.query-container {
  display: inline-block;
  padding: 0;
  width: 782px;
  margin-left: 150px; }

@media only screen and (max-width: 1024px) {
  div.srpc div.query-container {
    width: 100%;
    margin-left: 0px;
    margin-top: -20px; }
  div.srpc.start div.query, div.srpc.start div.dropdown {
    width: auto;
    margin: 0 10px;
    padding: 0;
    position: relative;
    left: auto;
    top: auto; }
  div.srpc.start div.dropdown-container {
    padding-right: 0; } }

div.country-picker {
  padding: 10px; }
  div.country-picker .row {
    position: relative;
    padding: 18px 27px;
    max-width: 800px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1); }
    @media only screen and (max-width: 1024px) {
      div.country-picker .row {
        margin-top: 0; } }
  div.country-picker a.close {
    font-size: 48px;
    color: #ccc;
    position: absolute;
    top: -10px;
    right: 20px;
    text-decoration: none; }
  div.country-picker div.form {
    position: relative; }
    div.country-picker div.form div.pull-right {
      position: absolute;
      bottom: 0;
      right: 0; }
      @media only screen and (max-width: 1024px) {
        div.country-picker div.form div.pull-right {
          position: relative;
          text-align: center; } }
  div.country-picker label {
    display: block;
    padding-top: 16px; }
    div.country-picker label span {
      padding-left: 8px;
      vertical-align: middle; }
  div.country-picker select {
    background-color: white;
    height: 36px;
    line-height: 36px;
    min-width: 200px;
    width: 33%; }
    @media only screen and (max-width: 1024px) {
      div.country-picker select {
        width: 100%; } }
  div.country-picker button {
    margin-left: auto; }

button, a.button {
  height: 36px;
  line-height: 27px;
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  color: #757575;
  cursor: default;
  font-size: 13px;
  font-weight: bold;
  min-width: 54px;
  padding: 0 16px;
  text-align: center;
  width: 150px;
  outline: none; }
  button.button-primary, a.button.button-primary {
    background-image: -webkit-linear-gradient(top, #3979a9, #3875a4);
    background-color: #3876a5;
    border: 1px solid #3876a5;
    color: #f9fbfd; }
    button.button-primary:hover, a.button.button-primary:hover {
      border: 1px solid #3e84b8;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      color: #fff; }

a.button {
  display: inline-block;
  text-decoration: none;
  line-height: 34px; }

button:hover {
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #222; }

button:focus {
  border: 1px solid #4d90fe;
  outline: none; }

nav.navbar {
  position: relative; }
  nav.navbar a {
    display: inline-block;
    text-decoration: none;
    margin-left: auto;
    padding: 10px 20px;
    color: #777;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0; }
    nav.navbar a:hover {
      color: black; }
      nav.navbar a:hover i.down {
        border-color: black; }
  nav.navbar i.down {
    border: solid #777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin: 0 0 2px 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

div.srpc nav.navbar {
  height: 0px; }
  div.srpc nav.navbar a {
    margin-top: -20px; }

.popper {
  background: #f5f5f5;
  color: #333;
  width: 200px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); }
  .popper ul {
    margin: 0;
    padding: 0px;
    list-style-type: none; }
    .popper ul li {
      padding: 0px; }
    .popper ul a {
      padding: 5px 10px;
      text-decoration: none;
      display: block;
      color: #333;
      font-size: 16px; }
      .popper ul a:hover {
        background-color: white; }

.popper .popper__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px; }

.popper[data-placement^="top"] {
  margin-bottom: 5px; }

.popper[data-placement^="top"] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #f5f5f5 transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0; }

.popper[data-placement^="bottom"] {
  margin-top: 5px; }

.popper[data-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #f5f5f5 transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0; }

.popper[data-placement^="right"] {
  margin-left: 5px; }

.popper[data-placement^="right"] .popper__arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent #f5f5f5 transparent transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0; }

.popper[data-placement^="left"] {
  margin-right: 5px; }

.popper[data-placement^="left"] .popper__arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #f5f5f5;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0; }

.popper[data-x-out-of-boundaries] {
  display: none; }

i.down {
  border: solid #aaa;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 3px 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }
